import { GeoJSON } from "geojson";
import { Authorisation } from "@iventis/domain-model/model/authorisation";
import { DeepPartial, OptionalExceptFor, TupleToUnion } from "@iventis/types/useful.types";
/* eslint-disable @typescript-eslint/ban-types */
import { ActorRef } from "xstate";
import { ModeOfTransport } from "@iventis/domain-model/model/modeOfTransport";
import { AnySupportedGeometry, MapObjectProperties, RouteWaypoint, SelectedMapObject } from "@iventis/map-types";
import { CompositionMapObject, MapCursor, TypedFeature } from "../types/internal";
import { DrawingObject, SelectedMapComment } from "../types/store-schema";
import { Midpoint } from "../utilities/midpoint-handles";
import { ContinueDrawingDirection } from "../utilities/continue-drawing";

/*
    DRAWING MACHINE TYPES
*/

export enum DrawingEventNames {
    CLICK_DELETE = "CLICK_DELETE",
    CLICK_MIDPOINT = "CLICK_MIDPOINT",
    DRAG_MIDPOINT = "DRAG_MIDPOINT",
    NODE_DRAG_START = "NODE_DRAG_START",
    OVER_SELECTED_OBJECT = "OVER_SELECTED_OBJECT",
    LEAVE_SELECTED_OBJECT = "LEAVE_SELECTED_OBJECT",
    OBJECT_DRAG_START = "OBJECT_DRAG_START",
    ROTATOR_DRAG_START = "ROTATOR_DRAG_START",
    MOVE_CLOSE_TO_NODE = "MOVE_CLOSE_TO_NODE",
    DRAG_END = "DRAG_END",
    OBJECT_DRAG_END = "OBJECT_DRAG_END",
    MOVE_AWAY = "MOVE_AWAY",
    ENTER_DELETE = "ENTER_DELETE",
    LEAVE_DELETE = "LEAVE_DELETE",
    ENTER_NODE = "ENTER_NODE",
    LEAVE_NODE = "LEAVE_NODE",
    ENTER_MIDPOINT = "ENTER_MIDPOINT",
    LEAVE_MIDPOINT = "LEAVE_MIDPOINT",
    ENTER_ROTATOR = "ENTER_ROTATOR",
    LEAVE_ROTATOR = "LEAVE_ROTATOR",
    APPEND = "APPEND",
    OVER_CONTINUE_DRAWING = "OVER_CONTINUE_DRAWING",
    UPDATE_GEOMETRY = "UPDATE_GEOMETRY",
    NODE_CLICKED = "NODE_CLICKED",
}

export type DrawingMachineEvents =
    | AddCoordinateDeleteEvent
    | BeginDragEvent
    | BeginObjectDragEvent
    | BeginRotatorDragEvent
    | EndRotationDragEvent
    | EndNodeDragEvent
    | DeleteNodeEvent
    | AppendEvent
    | EnterNodeEvent
    | ClickMidpointEvent
    | DragMidpointEvent
    | EndObjectDraggingEvent
    | EnterMidpointEvent
    | NodeClickedEvent
    | EventsWithNoPayload<typeof machineEventTypes.overSelectedObject>
    | EventsWithNoPayload<typeof machineEventTypes.leaveSelectedObject>
    | EventsWithNoPayload<typeof machineEventTypes.clickDelete>
    | EventsWithNoPayload<typeof machineEventTypes.clickMidpoint>
    | EventsWithNoPayload<typeof machineEventTypes.enterDelete>
    | EventsWithNoPayload<typeof machineEventTypes.leaveDelete>
    | EventsWithNoPayload<typeof machineEventTypes.leaveNode>
    | EventsWithNoPayload<typeof machineEventTypes.enterRotator>
    | EventsWithNoPayload<typeof machineEventTypes.leaveRotator>
    | EventsWithNoPayload<typeof machineEventTypes.leaveMidpoint>
    | EventsWithNoPayload<typeof machineEventTypes.moveAway>
    | EventsWithNoPayload<typeof machineEventTypes.overContinueDrawing>;

export enum OptionalDrawingBehaviour {
    OBJECT_DRAG = "OBJECT_DRAG",
    CONTINUE_DRAW = "CONTINUE_DRAW",
}

export enum DrawingCallbacks {
    ROTATION_HAS_ENDED = "ROTATION_HAS_ENDED",
    ROTATION_HAS_STARTED = "ROTATION_HAS_STARTED",
    NODE_DRAG_HAS_STARTED = "NODE_DRAG_HAS_STARTED",
    NODE_DRAG_HAS_ENDED = "NODE_DRAG_HAS_ENDED",
    MIDPOINT_DRAG_HAS_STARTED = "MIDPOINT_DRAG_HAS_STARTED",
    MIDPOINT_DRAG_HAS_ENDED = "MIDPOINT_DRAG_HAS_ENDED",
    ENTER_HOVER = "ENTER_HOVER",
    EXIT_HOVER = "EXIT_HOVER",
    ADD_MIDPOINT = "ADD_MIDPOINT",
    DELETE_NODE = "DELETE_NODE",
    OBJECT_DRAG_HAS_ENDED = "OBJECT_DRAG_HAS_ENDED",
}

export type AddMidpointEvent = {
    type: DrawingCallbacks.ADD_MIDPOINT;
    payload: { point: Midpoint; dragging: true } | { point: Midpoint; dragging: false; object?: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> };
};
export type RotationHasStarted = { type: DrawingCallbacks.ROTATION_HAS_STARTED; payload: BeginRotatorDragEvent["payload"] };
export type RotationHasEnded = { type: DrawingCallbacks.ROTATION_HAS_ENDED; payload: EndRotationDragEvent["payload"] };
export type NodeDragHasEnded = { type: DrawingCallbacks.NODE_DRAG_HAS_ENDED; payload: EndNodeDragEvent["payload"] };
export type MidpointDragHasEnded = { type: DrawingCallbacks.MIDPOINT_DRAG_HAS_ENDED; payload: EndNodeDragEvent["payload"] };
export type NodeDragHasStarted = { type: DrawingCallbacks.NODE_DRAG_HAS_STARTED; payload: BeginDragEvent["payload"] };
export type ObjectDragHasEnded = { type: DrawingCallbacks.OBJECT_DRAG_HAS_ENDED; payload: EndObjectDraggingEvent["payload"] };

export type DrawingCallbackEvents =
    | AddMidpointEvent
    | RotationHasStarted
    | RotationHasEnded
    | NodeDragHasStarted
    | NodeDragHasEnded
    | ObjectDragHasEnded
    | UpdateDrawingGeometryEvent
    | EventsWithNoPayload<DrawingCallbacks.MIDPOINT_DRAG_HAS_STARTED>
    | MidpointDragHasEnded
    | EventsWithNoPayload<DrawingCallbacks.DELETE_NODE>
    | EventsWithNoPayload<DrawingCallbacks.ENTER_HOVER>
    | EventsWithNoPayload<DrawingCallbacks.EXIT_HOVER>;

export type DrawingMachineContext = {
    defaultCursor: MapCursor;
    /** Behaviours with which you would like the drawing machine to perform, such as object dragging and continue drawing */
    includeBehaviours: OptionalDrawingBehaviour[];
};

export interface Append {
    feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
    waypoints: RouteWaypoint[] | null;
}

export interface AppendEvent {
    type: typeof machineEventTypes.append;
    payload: Append;
}

export interface EnterNodeEvent {
    type: typeof machineEventTypes.enterNode;
    payload: AddCoordinateDelete & { cursor?: MapCursor };
}

export interface BeginDrag {
    layerId: string;
    objectId: string;
}

export interface BeginRotatorDragEvent {
    type: typeof machineEventTypes.rotatorDragStart;
    payload: BeginDrag[];
}

export interface BeginDragEvent {
    type: typeof machineEventTypes.nodeDragStart;
    payload: { feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>; cursor: MapCursor };
}

export interface NodeClickedEvent {
    type: typeof machineEventTypes.nodeClicked;
    payload: { feature: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>; point: GeoJSON.Position };
}

export interface BeginObjectDragEvent {
    type: typeof machineEventTypes.objectDragStart;
    payload: { lng: number; lat: number };
}

export interface ClickMidpointEvent {
    type: typeof machineEventTypes.clickMidpoint;
    // Send the object through this payload because it is a dependency of showing the coordinate delete handle
    payload: { point: Midpoint; object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties> };
}

export interface DragMidpointEvent {
    type: typeof machineEventTypes.dragMidpoint;
    payload: { point: Midpoint; createNode: boolean };
}

export interface AddCoordinateDelete {
    coordinate: GeoJSON.Position;
    object: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
}
export interface AddCoordinateDeleteEvent {
    type: typeof machineEventTypes.moveCloseToNode;
    payload: AddCoordinateDelete;
}

export interface EndObjectDraggingEvent {
    type: typeof machineEventTypes.objectDragEnd;
    payload: CompositionMapObject[];
}

export interface EndRotationDragEvent {
    type: typeof machineEventTypes.dragEnd;
    payload: CompositionMapObject[];
}

export interface EndNodeDrag {
    transformed: GeoJSON.Feature<AnySupportedGeometry, MapObjectProperties>;
    coordinate?: GeoJSON.Position;
}

export interface EndNodeDragEvent {
    type: typeof machineEventTypes.dragEnd;
    payload: EndNodeDrag & { cursor?: MapCursor };
}

export interface EnterMidpointEvent {
    type: typeof machineEventTypes.enterMidpoint;
    payload?: { cursor: MapCursor };
}

export type DrawingStates = [
    "closeToNode",
    "closeToNode.default",
    "closeToNode.hoverOverDelete",
    "closeToNode.hoverOverNode",
    "default",
    "hoverOverMidpoint",
    "hoverOverRotator",
    "nodeDragging",
    "midpointDragging",
    "objectDragging",
    "rotating"
];

export type DrawingState = TupleToUnion<DrawingStates>;

/*
    MODE MACHINE TYPES
*/

export enum ModeEventNames {
    LOADED = "LOADED",
    TOGGLE_EDIT = "TOGGLE_EDIT",
    COMPOSE = "COMPOSE",
    SELECT_OBJECTS = "SELECT_OBJECTS",
    FINISH = "FINISH",
    SELECT_LAYER = "SELECT_LAYER",
    PREVIEW = "PREVIEW",
    COPY_OBJECTS = "COPY_OBJECTS",
    ZOOM_IN = "ZOOM_IN",
    ZOOM_OUT = "ZOOM_OUT",
    SET_PITCH = "SET_PITCH",
    RESET_POSITION = "RESET_POSITION",
    TOGGLE_ANALYSIS = "TOGGLE_ANALYSIS",
    CONTINUE_DRAWING = "CONTINUE_DRAWING",
    COMPOSE_ANALYSIS = "COMPOSE_ANALYSIS",
    TOGGLE_AREA_SELECT = "TOGGLE_AREA_SELECT",
    DELETE_OBJECT = "DELETE_OBJECT",
    UPDATE_GEOMETRY = "UPDATE_GEOMETRY",
    SET_LAYER_PERMISSIONS = "SET_LAYER_PERMISSIONS",
    RefreshAttributeListItems = "REFRESH_ATTRIBUTE_LIST_ITEMS",
    UPDATE_MODE_OF_TRANSPORT = "UPDATE_MODE_OF_TRANSPORT",
    UPDATE_ROUTE_WAYPOINTS = "UPDATE_ROUTE_WAYPOINTS",
    TOGGLE_COMMENTS = "TOGGLE_COMMENTS",
    SELECT_COMMENT = "SELECT_COMMENT",
    DELETE_COMMENT = "DELETE_COMMENT",
    ADD_MOBILE_COMMENT = "ADD_MOBILE_COMMENT",
    CONFIRM_COMMENT_MOVE = "CONFIRM_COMMENT_MOVE",
    CANCEL_COMMENT_MOVE = "CANCEL_COMMENT_MOVE",
    EXIT_COMMENTS = "EXIT_COMMENTS",
}

export interface SelectObjects {
    selectedMapObjectIds: SelectedMapObject[];
}
export interface SelectObjectsEvent {
    type: typeof machineEventTypes.selectObjects;
    payload: { selectedMapObjectIds: SelectedMapObject[]; from: string };
}

export interface SelectLayer {
    layerId: string;
}

export interface SelectLayerEvent {
    type: typeof machineEventTypes.selectLayer;
    payload: SelectLayer;
}

export interface FinishDrawingEvent {
    type: typeof machineEventTypes.finish;
    payload?: { cancel?: boolean; updateStore?: boolean; cancelToGeometry?: { feature: AnySupportedGeometry; waypoints: RouteWaypoint[] | null } };
}

export type BeginCompose = {
    objects: OptionalExceptFor<DrawingObject, "layerId", DeepPartial<DrawingObject>>[];
    duplication?: boolean;
    fromCoordinate?: GeoJSON.Position;
    direction?: ContinueDrawingDirection;
};

export type BeginAnalysisCompose = {
    objects: OptionalExceptFor<DrawingObject, "layerId">[];
    fromCoordinate?: GeoJSON.Position;
    direction?: ContinueDrawingDirection;
};

export interface BeginComposeEvent {
    type: typeof machineEventTypes.compose | typeof machineEventTypes.dragEnd | typeof machineEventTypes.moveAway | typeof machineEventTypes.continueDrawing;
    payload: BeginCompose;
}

export interface BeginAnalysisComposeEvent {
    type: typeof machineEventTypes.composeAnalysis | typeof machineEventTypes.dragEnd | typeof machineEventTypes.moveAway | typeof machineEventTypes.continueDrawing;
    payload: BeginAnalysisCompose;
}

export interface AreaSelectEvent {
    type: typeof machineEventTypes.areaSelect;
    payload: BeginCompose;
}

export type ModeContext = {
    drawingMachineRef: ActorRef<DrawingMachineEvents> | undefined;
    defaultCursor: MapCursor;
    hasExitedEditMode: boolean;
    layerPermissions: { [layerId: string]: Authorisation };
};

export type RefreshAttributeListItems = {
    type: typeof machineEventTypes.refreshAttributeListItems;
    payload: { layerId: string; attributeId: string };
};

export type UpdateModeOfTransport = {
    type: typeof machineEventTypes.updateModeOfTransport;
    payload: { modeOfTransport: ModeOfTransport };
};

export type UpdateRouteWaypoints = {
    type: typeof machineEventTypes.updateRouteWaypoints;
    payload: { waypoints: RouteWaypoint[] };
};

export type SelectableComment = Omit<SelectedMapComment, "canvasCoordinates"> & { position: GeoJSON.Position };

export type SelectCommentEvent = {
    type: typeof machineEventTypes.selectComment;
    payload: SelectableComment;
    from: string;
    focus?: boolean;
};

export type DeleteCommentEvent = {
    type: typeof machineEventTypes.deleteComment;
    commentId: string;
};

export type ExitComments = {
    type: typeof machineEventTypes.exitComments;
    keepCommentsMachineAlive: boolean;
};

/** Use these events for external mode events */
export type ModeEvents =
    | BeginComposeEvent
    | SelectObjectsEvent
    | SelectLayerEvent
    | FinishDrawingEvent
    | UpdateDrawingGeometryEvent
    | DeleteNodeEvent
    | BeginAnalysisComposeEvent
    | AreaSelectEvent
    | DeleteObjectEvent
    | SetLayerPermissions
    | RefreshAttributeListItems
    | UpdateModeOfTransport
    | UpdateRouteWaypoints
    | SelectCommentEvent
    | DeleteCommentEvent
    | ExitComments
    | EventsWithNoPayload<typeof machineEventTypes.toggleComments>
    | EventsWithNoPayload<typeof machineEventTypes.loaded>
    | EventsWithNoPayload<typeof machineEventTypes.toggleEdit>
    | EventsWithNoPayload<typeof machineEventTypes.preview>
    | EventsWithNoPayload<typeof machineEventTypes.copyObjects>
    | EventsWithNoPayload<typeof machineEventTypes.zoomIn>
    | EventsWithNoPayload<typeof machineEventTypes.zoomOut>
    | EventsWithNoPayload<typeof machineEventTypes.setPitch>
    | EventsWithNoPayload<typeof machineEventTypes.resetPosition>
    | EventsWithNoPayload<typeof machineEventTypes.toggleAnalysis>
    | EventsWithNoPayload<typeof machineEventTypes.continueDrawing>
    | EventsWithNoPayload<typeof machineEventTypes.addCommentInMobile>
    | EventsWithNoPayload<typeof machineEventTypes.confirmCommentMove>
    | EventsWithNoPayload<typeof machineEventTypes.cancelCommentMove>;

/** Use these for internal mode events */
export type ModeMachineEventsWithDrawingCallbacks = ModeEvents | DrawingCallbackEvents;

export type MapModes = ["loading", "read", "edit.default", "edit.composition", "analysis.default", "analysis.composition", "areaSelect", "preview", "edit.addComment"];

export type MapMode = TupleToUnion<MapModes>;

/*
    SHARED MACHINE TYPES
*/

export const machineEventTypes = {
    // Mode events
    loaded: ModeEventNames.LOADED,
    toggleEdit: ModeEventNames.TOGGLE_EDIT,
    compose: ModeEventNames.COMPOSE,
    selectObjects: ModeEventNames.SELECT_OBJECTS,
    finish: ModeEventNames.FINISH,
    selectLayer: ModeEventNames.SELECT_LAYER,
    preview: ModeEventNames.PREVIEW,
    copyObjects: ModeEventNames.COPY_OBJECTS,
    zoomIn: ModeEventNames.ZOOM_IN,
    zoomOut: ModeEventNames.ZOOM_OUT,
    setPitch: ModeEventNames.SET_PITCH,
    resetPosition: ModeEventNames.RESET_POSITION,
    toggleAnalysis: ModeEventNames.TOGGLE_ANALYSIS,
    continueDrawing: ModeEventNames.CONTINUE_DRAWING,
    composeAnalysis: ModeEventNames.COMPOSE_ANALYSIS,
    areaSelect: ModeEventNames.TOGGLE_AREA_SELECT,
    deleteObject: ModeEventNames.DELETE_OBJECT,
    setLayerPermission: ModeEventNames.SET_LAYER_PERMISSIONS,
    refreshAttributeListItems: ModeEventNames.RefreshAttributeListItems,
    updateModeOfTransport: ModeEventNames.UPDATE_MODE_OF_TRANSPORT,
    updateRouteWaypoints: ModeEventNames.UPDATE_ROUTE_WAYPOINTS,
    toggleComments: ModeEventNames.TOGGLE_COMMENTS,
    exitComments: ModeEventNames.EXIT_COMMENTS,
    selectComment: ModeEventNames.SELECT_COMMENT,
    deleteComment: ModeEventNames.DELETE_COMMENT,
    addCommentInMobile: ModeEventNames.ADD_MOBILE_COMMENT,
    confirmCommentMove: ModeEventNames.CONFIRM_COMMENT_MOVE,
    cancelCommentMove: ModeEventNames.CANCEL_COMMENT_MOVE,

    // Drawing Events
    clickDelete: DrawingEventNames.CLICK_DELETE,
    clickMidpoint: DrawingEventNames.CLICK_MIDPOINT,
    dragMidpoint: DrawingEventNames.DRAG_MIDPOINT,
    nodeDragStart: DrawingEventNames.NODE_DRAG_START,
    overSelectedObject: DrawingEventNames.OVER_SELECTED_OBJECT,
    leaveSelectedObject: DrawingEventNames.LEAVE_SELECTED_OBJECT,
    objectDragStart: DrawingEventNames.OBJECT_DRAG_START,
    rotatorDragStart: DrawingEventNames.ROTATOR_DRAG_START,
    moveCloseToNode: DrawingEventNames.MOVE_CLOSE_TO_NODE,
    dragEnd: DrawingEventNames.DRAG_END,
    objectDragEnd: DrawingEventNames.OBJECT_DRAG_END,
    moveAway: DrawingEventNames.MOVE_AWAY,
    enterDelete: DrawingEventNames.ENTER_DELETE,
    leaveDelete: DrawingEventNames.LEAVE_DELETE,
    enterNode: DrawingEventNames.ENTER_NODE,
    leaveNode: DrawingEventNames.LEAVE_NODE,
    enterMidpoint: DrawingEventNames.ENTER_MIDPOINT,
    leaveMidpoint: DrawingEventNames.LEAVE_MIDPOINT,
    enterRotator: DrawingEventNames.ENTER_ROTATOR,
    leaveRotator: DrawingEventNames.LEAVE_ROTATOR,
    append: DrawingEventNames.APPEND,
    overContinueDrawing: DrawingEventNames.OVER_CONTINUE_DRAWING,
    updateDrawingGeometry: DrawingEventNames.UPDATE_GEOMETRY,
    nodeClicked: DrawingEventNames.NODE_CLICKED,
} as const;

export interface DeleteObjectEvent {
    type: typeof machineEventTypes.deleteObject;
    payload: { updateStore: boolean };
}

export interface SetLayerPermissions {
    type: typeof machineEventTypes.setLayerPermission;
    payload: { [layerId: string]: Authorisation };
}

export type EventsWithNoPayload<T> = { type: T };

export interface DeleteNode {
    coordinate: GeoJSON.Position;
}
export interface ObjectPayload {
    objectIds: string[];
}
export interface DeleteNodeEvent {
    type: typeof machineEventTypes.clickDelete | DrawingCallbacks.DELETE_NODE;
    payload: DeleteNode;
}

export interface UpdateDrawingGeometryEvent {
    type: typeof machineEventTypes.updateDrawingGeometry;
    payload: { features: TypedFeature[]; updateStore: boolean };
}

export type MachineTypes = Pick<ModeEvents, "type">["type"];
