/* eslint-disable no-console */
import { FeatureCollection } from "@turf/helpers";
import { Point } from "geojson";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { load } from "@loaders.gl/core";
import { GLTFLoader, postProcessGLTF } from "@loaders.gl/gltf";
import { ImageLoader } from "@loaders.gl/images";
import { EMPTY_GUID } from "@iventis/utilities";
import { CUSTOM_IMAGE_MATERIAL_SLOT_NAME } from "@iventis/model-utilities";
import { getStaticStyleValue, getStaticStyleValueFromMapped } from "@iventis/layer-style-helpers";
import { MapObjectProperties } from "@iventis/map-types";
import { MapObject3D } from "../engine-3d-types";
import { MapObject3DScale, MapObject3DScaleAttribute } from "../engine-3d-scale-types";
import { defaultScale } from "../3d-engine-scale-helpers";
import { ModelLayerStyle } from "../../../../types/models";

export const DEFAULT_LIST_ITEM_ID = "default";

export const parseGeoJsonToMapObject3D = (
    geoJson: FeatureCollection<Point, MapObjectProperties>,
    layerId: string,
    layerScaleValue: MapObject3DScale,
    currentLevel: number,
    listItemIds: string[],
    layerName: string,
    datesFilter: boolean,
    modelAttributeId?: string
): { [layerId: string]: MapObject3D[] } => {
    const startingValue = { default: [] };
    listItemIds.forEach((listItemId) => {
        startingValue[listItemId] = [];
    });

    return geoJson.features.reduce((features, feature) => {
        if (currentLevel === feature.properties.level && (!datesFilter || !feature.properties.dateFiltered)) {
            const key = (feature.properties?.[modelAttributeId] as string) ?? DEFAULT_LIST_ITEM_ID;
            (features[key] ?? features[DEFAULT_LIST_ITEM_ID]).push({
                id: feature.properties.id,
                layerId,
                layerName,
                position: [feature.geometry.coordinates[0], feature.geometry.coordinates[1], 0],
                rotation: feature.properties.rotation,
                properties: feature.properties,
                scale: layerScaleValue?.type === "attribute" ? getAttributeBasedScaleValue(layerScaleValue, feature.properties) : layerScaleValue?.value ?? defaultScale.value,
            });
        }

        return features;
    }, startingValue);
};

export const getAttributeBasedScaleValue = (layerScaleValue: MapObject3DScaleAttribute, properties: MapObjectProperties) => {
    const scaleValue = layerScaleValue.value[(properties[layerScaleValue.dataFieldId] as string) ?? DEFAULT_LIST_ITEM_ID];
    return scaleValue ?? layerScaleValue.value[DEFAULT_LIST_ITEM_ID];
};

export const isModelAttributeBased = (model: ModelLayerStyle["model"]) => model?.extractionMethod === StyleValueExtractionMethod.Mapped;

export const getModelAndListItemId = (style: ModelLayerStyle) => {
    const mappedValues = Object.entries(style.model.mappedValues ?? {}).map(([listItemId, { staticValue }]) => ({ listItemId, modelId: staticValue }));
    return [...mappedValues, { listItemId: DEFAULT_LIST_ITEM_ID, modelId: getStaticStyleValueFromMapped(style.model) }];
};

export const getCustomImageDataFromStyle = async (style: ModelLayerStyle, getAsset: (id: string) => Promise<string>): Promise<Blob | null> => {
    const imageAssetId = getStaticStyleValue(style.customImage);
    if (imageAssetId === EMPTY_GUID || imageAssetId == null) {
        return null;
    }
    // get the image asset from the style
    const imageAsset = await getAsset(imageAssetId);
    const response = await fetch(imageAsset);
    // Make base64 image
    return response.blob();
};

/**
 * Loads a model and optionally replaces the texture with a custom image
 * @param model The model to load
 * @param customImage The custom image to replace the texture with
 * @returns The model with the custom image, if provided
 * */
export const loadModelWithImage = async (model: ArrayBuffer | string, customImage?: Blob) => {
    const rawModel = await load(model, GLTFLoader);
    if (!rawModel?.json) {
        // Do JSON property is required to post process the model, if it is not present something has gone horribly wrong, an outdated loaders.gl version might be the cause
        console.warn("Model json is missing, cannot post process model. Returning raw model.");
        return rawModel;
    }
    if (!customImage) {
        return postProcessGLTF(rawModel);
    }
    try {
        const rawImage = await load(customImage, ImageLoader, { image: { type: "data", decode: true } });
        const processed = postProcessGLTF(rawModel);

        // Next we can derive the index of the image we want to replace based on the processed model
        // Go through materials and find the texture which has a name which includes the material name in CUSTOM_IMAGE_MATERIAL_SLOT_NAME
        // Go to pbrMetallicRoughness -> BaseColourTexture -> Texutre -> source -> id
        // This id is the id of the image we want to replace
        const materialWithImageSlot = processed.materials.find(
            (material) => material.name.includes(CUSTOM_IMAGE_MATERIAL_SLOT_NAME) && material?.pbrMetallicRoughness?.baseColorTexture?.texture?.source?.id
        );
        const id = materialWithImageSlot?.pbrMetallicRoughness?.baseColorTexture?.texture?.source?.id ?? "";

        if (!id) {
            throw new Error(`No material slot matching "${CUSTOM_IMAGE_MATERIAL_SLOT_NAME}" was found in the model`);
        }

        // Now find the index of the image with the id in processed.images
        const imageIndex = processed.images.findIndex((image) => image.id === id);
        if (imageIndex === -1) {
            throw new Error("No image was found assigned to the material slot");
        }

        // Replace the image in the model with the new image
        const editedModel = rawModel;
        if (editedModel.images) {
            editedModel.images = editedModel.images.map((image, index) => {
                if (index === imageIndex) {
                    return rawImage;
                }
                return image;
            });
        }

        return postProcessGLTF(editedModel);
    } catch (e) {
        console.error("Failed to load custom image for model, returning model without image: ", e);
        return postProcessGLTF(rawModel);
    }
};
