import { BehaviorSubject, Subject, Subscription } from "rxjs";
import bearing from "@turf/bearing";
import distance from "@turf/distance";
import transformTranslate from "@turf/transform-translate";
import { point, featureCollection } from "@turf/helpers";
import { Feature, Point, FeatureCollection } from "geojson";
import { AnySupportedGeometry, MapObjectProperties } from "@iventis/map-types";
import { CompositionMapObject, MoveEvent, Listener, ClickEvent } from "../types/internal";

/**
 * Manages object dragging behaviour.
 * Reponsible for calculation of new geometries based on translations
 * Responsible for cleaning up after mouse event listeners
 */
export class ObjectDrag {
    private translation: BehaviorSubject<CompositionMapObject[]>;

    private translationOnRelease: Subject<CompositionMapObject[]>;

    private mouseMoveListener: Listener;

    private mouseUpListener: Listener;

    private objectPlaceListeners: Listener[];

    private mouseMoved = false;

    private centerPoint: Feature<Point>;

    private translationSubscriptions: Subscription[] = [];

    private featuresAsCollection: FeatureCollection<AnySupportedGeometry, MapObjectProperties>;

    constructor(
        private features: CompositionMapObject[],
        centerLngLat: { lng: number; lat: number },
        private operations: {
            onMouseMove: (callback: (e: MoveEvent) => void) => Listener;
            onMouseUp: (callback: (e: MoveEvent) => void) => Listener;
            /** Any event listeners that should result in the moving object to be dropped/placed on the map (i.e. click/paste) */
            placementListeners: ((callback: (e: ClickEvent) => void) => Listener)[];
            onRightClick?: (callback: (e: { lng: number; lat: number }) => void) => Listener;
        },
        startingCursorLngLat: { lng: number; lat: number },
        initialPositionCallback: (
            features: {
                geojson: Feature<AnySupportedGeometry, MapObjectProperties>;
                layerId: string;
                objectId: string;
            }[]
        ) => void
    ) {
        this.featuresAsCollection = featureCollection(this.features.map(({ geojson }) => geojson));
        this.centerPoint = point([centerLngLat.lng, centerLngLat.lat]);
        this.mouseMoveListener = operations.onMouseMove(this.onMouseMove.bind(this));
        this.objectPlaceListeners = this.operations.placementListeners.map((listener) => listener(this.onPlace.bind(this)));
        const initialTranslation = this.calculateTranslation(startingCursorLngLat);
        this.translation = new BehaviorSubject(initialTranslation);
        this.translationOnRelease = new Subject();

        // Before we move our cursor, we want to paint the object in it's starting cursor position
        initialPositionCallback(initialTranslation);
    }

    private initialiseMouseUpListener() {
        this.mouseUpListener = this.operations.onMouseUp(this.onMouseUp.bind(this));
    }

    private onPlace({ lng, lat }: ClickEvent) {
        this.translationOnRelease.next(this.calculateTranslation({ lng, lat }));
        this.objectPlaceListeners.forEach((listener) => listener.remove());
        this.mouseUpListener?.remove();
    }

    private onMouseUp({ lng, lat }: { lng: number; lat: number }) {
        this.translationOnRelease.next(this.calculateTranslation({ lng, lat }));
        this.mouseUpListener.remove();
    }

    private onMouseMove({ lng, lat }: { lng: number; lat: number }) {
        if (!this.mouseMoved) {
            this.mouseMoved = true;
            this.initialiseMouseUpListener();
        }

        this.translation.next(this.calculateTranslation({ lng, lat }));
    }

    private calculateTranslation({ lng, lat }: { lng: number; lat: number }) {
        const newPoint = point([lng, lat]);
        const distanceMoved = distance(this.centerPoint, newPoint);
        const bearingMoved = bearing(this.centerPoint, newPoint);
        const newFeatureCollection = transformTranslate(this.featuresAsCollection, distanceMoved, bearingMoved);
        return newFeatureCollection.features.map((feature, index) => ({ geojson: feature, layerId: this.features[index].layerId, objectId: this.features[index].objectId }));
    }

    /* Stop listening for mouse up events, useful if the user starts dragging the map */
    public abortMouseUpPlacement() {
        this.mouseUpListener.remove();
    }

    /* Resume listening to mouse up events, useful for when the user is finished dragging */
    public resumeMouseUpPlacement() {
        this.initialiseMouseUpListener();
    }

    public onTranslate(subscription: (geometryChange: CompositionMapObject[]) => void) {
        this.translationSubscriptions.push(this.translation.subscribe((translation) => subscription(translation)));
        return this;
    }

    public onRelease(subscription: (geometryChange: CompositionMapObject[]) => void) {
        this.translationSubscriptions.push(this.translationOnRelease.subscribe((translation) => subscription(translation)));
        return this;
    }

    public updateLevel(level: number) {
        this.features = this.features.map((feature) => ({ ...feature, level, geojson: { ...feature.geojson, properties: { ...feature.geojson.properties, level } } }));
        this.featuresAsCollection = featureCollection(this.features.map(({ geojson }) => geojson));
        this.translation.next(this.features);
    }

    public destroy() {
        this.mouseMoveListener.remove();
        this.mouseUpListener?.remove();
        this.objectPlaceListeners?.forEach((listener) => listener.remove());
        this.translationSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
