import { FixedShape } from "@iventis/domain-model/model/fixedShape";
import { ModeOfTransport } from "@iventis/domain-model/model/modeOfTransport";
import { COMMENTS_OBJECT_SORT_PROPERTY } from "./map-comment-constants";

export enum MeasurementScope {
    SEGMENT = "SEGMENT",
    WHOLE = "WHOLE",
    NEVER = "NEVER",
}

export type MapObjectProperties = {
    id: string;
    layerid: string;
    rotation: { x: number; y: number; z: number };
    measurementScope?: MeasurementScope;
    selected?: "selected";
    fixedShape?: FixedShape;
    level: number;
    modeOfTransport?: ModeOfTransport;
    dateFiltered?: boolean;
} & { [dataFieldId: string]: unknown };

export type MapCommentProperties = MapObjectProperties & { userId: string; imageId: string; [COMMENTS_OBJECT_SORT_PROPERTY]?: number };
