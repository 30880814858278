/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { MapboxLayer } from "@deck.gl/mapbox";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import { IIventisTestHelpers } from "./iventis-test-helpers";
import { MapObject3D } from "../3d-engine/engine-3d-types";
import { MapModuleLayer } from "../../../types/store-schema";

export class DeckGlTestHelpers implements IIventisTestHelpers<MapboxLayer<MapObject3D>, unknown> {
    toIventisLayer(layers: MapboxLayer<MapObject3D>[]): Partial<MapModuleLayer> {
        if (layers == null || layers.length === 0) return null;
        const layer = layers[0];

        switch (layer.props.metaData.styleType) {
            case StyleType.Model:
                return this.toIventisModelLayer(layers);
            case StyleType.LineModel:
                return this.toIventisLineModelLayer(layers);
            default:
                throw new Error(`Unsupported layer type: ${layer.props.metaData.styleType}`);
        }
    }

    toIventisModelLayer(layers: MapboxLayer<MapObject3D>[]): Partial<MapModuleLayer> {
        const layer = layers[0];
        return {
            id: layer.props.metaData.layerId,
            styleType: StyleType.Model,
            modelStyle: {
                styleType: StyleType.Model,
                model: this.parseStyleValue(layer.props.metaData.modelId),
                scale: this.getStyleValueFromObjects("scale", layer.props.data),
                height: undefined,
                width: undefined,
                length: undefined,
                objectOrder: undefined,
                customImage: undefined,
            },
        };
    }

    toIventisLineModelLayer(layers: MapboxLayer<MapObject3D>[]): Partial<MapModuleLayer> {
        const layer = layers[0];
        return {
            id: layer.props.metaData.layerId,
            styleType: StyleType.LineModel,
            lineModelStyle: {
                styleType: StyleType.LineModel,
                model: this.parseStyleValue(layer.props.metaData.modelId),
                spacing: undefined,
                scale: undefined,
                rotation: undefined,
                height: undefined,
                width: undefined,
                length: undefined,
                modelOffset: undefined,
                objectOrder: undefined,
                customImage: undefined,
            },
        };
    }

    public parseStyleValue<TStyleValue>(value: string): StyleValue<TStyleValue> {
        switch (typeof value) {
            case null:
                return null;
            case "string":
            case "boolean":
            case "number":
                return createStaticStyleValue<TStyleValue>(value as TStyleValue);
            default: {
                // eslint-disable-next-line no-console
                console.warn(`Unsupported style value type: ${typeof value}`);
                return null;
            }
        }
    }

    private getStyleValueFromObjects<TStyleValue>(styleProperty: string, objects: MapObject3D[]): StyleValue<TStyleValue> {
        switch (styleProperty) {
            case "scale": {
                if (objects[0] == null) {
                    console.warn(`No objects available to get scale value from`);
                    return null;
                }

                // If the scale value is the same for every model we know it is static
                const firstScaleValue = objects[0].scale[0];
                if (objects.every((object) => object.scale.every((scale) => scale === firstScaleValue))) {
                    return createStaticStyleValue(firstScaleValue) as StyleValue<TStyleValue>;
                }
                console.warn(`Unsupported scale property value`);
                return null;
            }
            default: {
                console.warn(`Unsupported style property: ${styleProperty}`);
                return null;
            }
        }
    }

    toIventisAreaLayer(): Partial<MapModuleLayer> {
        throw new Error("Method not implemented.");
    }

    toIventisLineLayer(): Partial<MapModuleLayer> {
        throw new Error("Method not implemented.");
    }
}
