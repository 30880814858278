/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import { StyleType } from "@iventis/domain-model/model/styleType";
import { StyleValueExtractionMethod } from "@iventis/domain-model/model/styleValueExtractionMethod";
import { Units } from "@iventis/domain-model/model/units";
import { ZoomableValueExtractionMethod } from "@iventis/domain-model/model/zoomableValueExtractionMethod";
import { CircleLayer, Expression, FillLayer, LineLayer, StyleFunction, SymbolLayer, Layer } from "mapbox-gl";
import { StyleValue } from "@iventis/domain-model/model/styleValue";
import { IconAlignment } from "@iventis/domain-model/model/iconAlignment";
import { createStaticStyleValue } from "@iventis/layer-style-helpers";
import { IIventisTestHelpers } from "./iventis-test-helpers";
import { MapModuleLayer } from "../../../types/store-schema";

type MapboxLayers = LineLayer | FillLayer | SymbolLayer | CircleLayer;

type MapboxStyles = string | Expression | StyleFunction | number | boolean;

export class MapboxTestHelpers implements IIventisTestHelpers<MapboxLayers, MapboxStyles> {
    public toIventisLayer(layers: Layer[]): Partial<MapModuleLayer> {
        if (layers == null || layers.length === 0) return null;
        const baseLayer = layers.find((layer) => layer.metadata.type === "base");
        switch (baseLayer.type) {
            case "line":
                return this.toIventisLineLayer(layers as MapboxLayers[]);
            case "fill":
                return this.toIventisAreaLayer(layers as MapboxLayers[]);
            case "circle":
                return this.toIventisPointLayer(layers as MapboxLayers[]);
            case "symbol":
                return this.toIventisIconLayer(layers as MapboxLayers[]);
            default:
                throw new Error(`Unsupported layer type: ${baseLayer.type}`);
        }
    }

    public toIventisAreaLayer(layers: MapboxLayers[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        const text = layers.find((layer) => layer.metadata?.type === "text");
        return {
            id: base.id,
            styleType: StyleType.Area,
            areaStyle: {
                styleType: StyleType.Area,
                colour: this.parseStyleValue(base.paint?.["fill-color"]),
                opacity: this.parseStyleValue(base.paint?.["fill-opacity"]),
                dimension: undefined,
                fill: undefined,
                simulation: undefined,
                simulationModel: undefined,
                simulationScale: undefined,
                simulationDirection: undefined,
                simulationDisturbution: undefined,
                grid: undefined,
                gridColour: undefined,
                gridOrientation: undefined,
                gridWidth: undefined,
                gridLength: undefined,
                height: undefined,
                outline: undefined,
                outlineColour: undefined,
                outlineOpacity: undefined,
                outlineWidth: undefined,
                outlineBlur: undefined,
                text: this.parseStyleValue(text != null),
                textContent: undefined,
                textColour: undefined,
                textSize: this.parseStyleValue(text?.layout?.["text-size"]),
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
            },
            visible: base.layout.visibility === "visible",
            source: base.source.toString(),
        };
    }

    public toIventisLineLayer(layers: MapboxLayers[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        const arrows = layers.find((layer) => layer.metadata?.type === "arrows");
        return {
            id: base.id,
            styleType: StyleType.Line,
            lineStyle: {
                styleType: StyleType.Line,
                colour: this.parseStyleValue(base.paint["line-color"]),
                type: undefined,
                width: undefined,
                opacity: undefined,
                offset: undefined,
                end: undefined,
                join: undefined,
                blur: undefined,
                dash: undefined,
                arrows: this.parseStyleValue(arrows != null),
                arrowColour: this.parseStyleValue(arrows?.paint?.["icon-color"]),
                arrowColourMatchesLine: undefined,
                arrowOpacity: undefined,
                arrowSize: undefined,
                arrowSpacing: this.parseStyleValue(arrows?.layout?.["symbol-spacing"]),
                isModel: undefined,
                model: undefined,
                iconPlacement: undefined,
                outline: undefined,
                outlineColour: undefined,
                outlineOpacity: undefined,
                outlineWidth: undefined,
                outlineBlur: undefined,
                text: undefined,
                textContent: undefined,
                textColour: undefined,
                textSize: undefined,
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
            },
            source: base.source.toString(),
            visible: base?.layout?.visibility === "visible",
        };
    }

    public toIventisPointLayer(layers: MapboxLayers[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        return {
            id: base.id,
            styleType: StyleType.Point,
            pointStyle: {
                styleType: StyleType.Point,
                colour: undefined,
                opacity: undefined,
                blur: undefined,
                radius: undefined,
                outline: undefined,
                outlineColour: undefined,
                outlineWidth: undefined,
                outlineOpacity: undefined,
                pitchAlignment: undefined,
                text: undefined,
                textContent: undefined,
                textColour: undefined,
                textSize: undefined,
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
            },
            source: base.source.toString(),
        };
    }

    public toIventisIconLayer(layers: MapboxLayers[]): Partial<MapModuleLayer> {
        const base = layers.find((layer) => layer.metadata?.type === "base");
        return {
            id: base.id,
            styleType: StyleType.Icon,
            iconStyle: {
                styleType: StyleType.Icon,
                iconImage: this.parseStyleValue(base.layout?.["icon-image"]),
                opacity: this.parseStyleValue(base.paint?.["icon-opacity"]),
                size: this.parseStyleValue(base.layout?.["icon-size"]),
                rotation: this.parseStyleValue(base.layout?.["icon-rotate"]),
                orientation: undefined,
                customColour: undefined,
                colour: undefined,
                allowOverlap: undefined,
                iconTextFit: undefined,
                iconTextFitMargin: undefined,
                text: this.parseStyleValue(base.layout?.["text-field"] != null),
                textContent: undefined,
                textColour: undefined,
                textSize: this.parseStyleValue(base.layout?.["text-size"]),
                textOverlap: undefined,
                textBold: undefined,
                textItalic: undefined,
                textUnderlined: undefined,
                textOutlineWidth: undefined,
                textOutlineColour: undefined,
                textOpacity: undefined,
                textPosition: undefined,
                textOffset: undefined,
                objectOrder: undefined,
                iconAlignment: this.parseStyleValue(base.layout?.["icon-rotation-alignment"], "iconAlignment"),
            },
            source: base.source.toString(),
            visible: base?.layout?.visibility === "visible",
        };
    }

    public parseStyleValue<TStyleValue>(value: MapboxStyles, styleProperty?: string): StyleValue<TStyleValue> {
        if (value == null) {
            return null;
        }

        switch (styleProperty) {
            case "iconAlignment":
                if (value === "map") {
                    return createStaticStyleValue(IconAlignment.North) as StyleValue<TStyleValue>;
                }
                if (value === "viewport") {
                    return createStaticStyleValue(IconAlignment.Screen) as StyleValue<TStyleValue>;
                }
                break;
            default:
        }

        switch (typeof value) {
            case null:
                return null;
            case "string":
            case "boolean":
            case "number":
                return createStaticStyleValue<TStyleValue>(value as TStyleValue);
            default: {
                const parsedValue: StyleValue<TStyleValue> = this.parseAttributeBasedStyleValue(value as Expression);

                if (parsedValue != null) {
                    return (parsedValue as StyleValue<TStyleValue>) as StyleValue<TStyleValue>;
                }
                // eslint-disable-next-line no-console
                console.warn(`Unsupported style value type: ${typeof value}`);
                return null;
            }
        }
    }

    private parseAttributeBasedStyleValue<TStyleValue>(value: Expression): StyleValue<TStyleValue> {
        if (value == null) {
            return null;
        }

        if (value[0] === "case") {
            const dataFieldId = value[1][2][1];
            const defaultValue = value[value.length - 1];
            const mappedValues = {};
            value.forEach((val, index) => {
                if (index % 2 === 1 && index !== value.length - 1) {
                    const listItemId = val[1];
                    const styleValue = value[index + 1];
                    mappedValues[listItemId] = styleValue;
                }
            });

            return {
                extractionMethod: StyleValueExtractionMethod.Mapped,
                staticValue: {
                    extractionMethod: ZoomableValueExtractionMethod.Static,
                    mappedZoomValues: null,
                    staticValue: defaultValue,
                    unitType: Units.None,
                },
                mappedValues,
                dataFieldId,
            };
        }
        return null;
    }

    toIventisModelLayer(): Partial<MapModuleLayer> {
        throw new Error("Method not implemented.");
    }

    toIventisLineModelLayer(): Partial<MapModuleLayer> {
        throw new Error("Method not implemented.");
    }
}
